import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Grid from '../components/tiles/grid'
import Pagination from '../components/Pagination'
import MediaQuery from 'react-responsive'

const Category = props => {
  const { data, pageContext } = props
  const { edges: posts, totalCount } = data.allWordpressPost
  const { title: siteTitle } = data.site.siteMetadata
  const { name: category } = pageContext
  const { humanPageNumber } = pageContext

  const palette = (pageContext.slug == "edge-of-the-world" || pageContext.slug == "bailehart") ? pageContext.slug : "grey";
  const ratio = (palette == "grey") ? .33 : .8;

  return (
    <Layout>
      <div>
      <Helmet title={`${category} | Page ${humanPageNumber} | ${siteTitle}`} />
      <div>
      <div>
      <MediaQuery maxWidth={639}>
        <Grid
          hasTitle={true}
          palette={palette}
          ratio={ratio}
          size={22}
        >
          <b>Category</b>
          <h1>{category}</h1>
        </Grid>
      </MediaQuery>

      <MediaQuery minWidth={640} maxWidth={959}>
        <Grid
          hasTitle={true}
          palette={palette}
          ratio={ratio}
          size={176}
        >
          <b>Category</b>
          <h1>{category}</h1>
        </Grid>
      </MediaQuery>

      <MediaQuery minWidth={960} maxWidth={1439}>
        <Grid
          hasTitle={true}
          palette={palette}
          ratio={ratio}
          size={217}
        >
          <b>Category</b>
          <h1>{category}</h1>
        </Grid>
      </MediaQuery>

      <MediaQuery minWidth={1440}>
        <Grid
          hasTitle={true}
          palette={palette}
          ratio={ratio}
          size={484}
        >
          <b>Category</b>
          <h1>{category}</h1>
        </Grid>
      </MediaQuery>
      </div>
      <PostList posts={posts}/>
      <Pagination pageContext={pageContext} pathPrefix="/" />
      </div>
      </div>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
